import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/CarrierPage/Header"
import DescriptionComponent from "../components/CarrierPage/DescriptionComponent"
import FormComponent from "../components/ContactPage/FormComponent"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"

const CarrierPage = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const [dataContact, setDataContact] = useState({})
  const isBrowser = typeof window !== "undefined"
  const query = useStaticQuery(graphql`
    query Career {
      polish: contentfulCareer(node_locale: { eq: "pl-PL" }) {
        slider1
        slider2
        team1 {
          raw
        }
        work_with_us
        work
      }

      english: contentfulCareer(node_locale: { eq: "en-US" }) {
        slider1
        slider2
        team1 {
          raw
        }
        work_with_us
        work
      }

      polishContact: contentfulContact(node_locale: { eq: "pl-PL" }) {
        send
        rodo {
          rodo
        }
        message
        name
        mail
        phone
        contact_form
        sendCv
        formSubtitle
        validateName
        validatePhone
        validateEmail
        validateMessage
        validateCheckbox
        validateCv
        validateMessageSend
        validateErrors
        validateName2
      }
      englishContact: contentfulContact(node_locale: { eq: "en-US" }) {
        send
        rodo {
          rodo
        }
        message
        name
        mail
        phone
        contact_form
        sendCv
        formSubtitle
        validateName
        validatePhone
        validateEmail
        validateMessage
        validateCheckbox
        validateCv
        validateMessageSend
        validateErrors
        validateName2
      }
    }
  `)

  useEffect(() => {
    if (lang === "en") {
      navigate("/career/")
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
        setDataContact(query.englishContact)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
        setDataContact(query.polishContact)
      }
    }
  }, [intl])

  const translationsContact = {
    send: dataContact.send,
    rodo: dataContact.rodo && dataContact.rodo.rodo,
    message: dataContact.message,
    name: dataContact.name,
    mail: dataContact.mail,
    phone: dataContact.phone,
    contact_form: dataContact.contact_form,
    sendCv: dataContact.sendCv,
    validateName: dataContact.validateName,
    validatePhone: dataContact.validatePhone,
    validateEmail: dataContact.validateEmail,
    validateMessage: dataContact.validateMessage,
    validateCheckbox: dataContact.validateCheckbox,
    validateCv: dataContact.validateCv,
    validateMessageSend: dataContact.validateMessageSend,
    validateErrors: dataContact.validateErrors,
    validateName2: dataContact.validateName2,
    formSubtitle: dataContact.formSubtitle,
    work_with_us: data.work_with_us,
  }

  return (
    <Layout>
      <Seo title="Kariera" />
      <Header slider1={data.slider1} slider2={data.slider2} work={data.work} />
      <DescriptionComponent team1={data.team1} />
      <FormComponent
        message={dataContact.message}
        translations={translationsContact}
        enableAttachment
      />
    </Layout>
  )
}

export default CarrierPage
