import styled from "styled-components"
import carrierDescriptionImage from "../../../images/carrier-description-image.jpg"

export const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 90px 0 41px 0;

  @media (min-width: 1400px) {
    height: 900px;
  }

  @media (max-width: 1200px) {
    height: 780px;
  }

  @media (max-width: 1149px) {
    width: 82.5%;
    height: 900px;
  }

  @media (max-width: 799px) {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
`

export const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  background: linear-gradient(
    133.89deg,
    #bb913c 0%,
    #eae69c 51.98%,
    #b6872d 108.48%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 42px 0 41px 0;

  @media (max-width: 1149px) {
    width: 70%;
  }

  @media (max-width: 799px) {
    width: 100%;
    height: auto;
  }

  & > div {
    width: 83.3%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 1149px) {
      gap: 30px;
    }

    h4 {
      font-size: 2.3rem;
      font-weight: 700;
      line-height: 3.5rem;
      letter-spacing: 0.06em;
      color: #030303;
    }
    p {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2.1rem;
      letter-spacing: 0;
      color: #030303;

      &:nth-of-type(1),
      &:nth-of-type(4) {
        padding-bottom: 30px;
        border-bottom: 1px solid #030303;
      }
    }
  }
`

export const RightSide = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${carrierDescriptionImage});
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 1149px) {
    background-size: cover;
    background-position: center;
    width: 30%;
  }

  @media (max-width: 799px) {
    width: 100%;
    aspect-ratio: 4/3;
  }
`
