import React from "react"
import { Wrapper, LeftSide, Main, RightSide } from "./style"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const DescriptionComponent = ({ team1 }) => (
  <Main>
    <Wrapper>
      <LeftSide>
        <div>{team1 && renderRichText(team1)}</div>
      </LeftSide>
      <RightSide></RightSide>
    </Wrapper>
  </Main>
)

export default DescriptionComponent
