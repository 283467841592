import React from "react"
import { Main, LogoBox, Wrapper, SpanBox } from "./style"
import whiteLogo from "../../../images/white-logo.png"

const Header = ({ slider1, slider2, work }) => {
  return (
    <Main>
      <Wrapper>
        <SpanBox>
          <LogoBox>
            <h1>{work}</h1>
            <img src={whiteLogo} alt="logo" />
          </LogoBox>
          <h2>{slider1}</h2>
          <h2>{slider2}</h2>
        </SpanBox>
      </Wrapper>
    </Main>
  )
}

export default Header
