import styled from "styled-components"
import carrierBackground from "../../../images/carrier-background.jpg"

export const SpanBox = styled.div`
  margin-bottom: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 999px) {
    gap: 20px;
  }
`

export const Main = styled.div`
  background: url(${carrierBackground}) top center no-repeat fixed;
  //background-size: 100% auto;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8.5rem;
  gap: 13px;
  position: relative;
  aspect-ratio: 16/9;
  background-size: cover;


  @supports (-webkit-touch-callout: none) {
    background: url(${carrierBackground}) center top no-repeat scroll;
    background-size: auto 100vh;
  }

  @media (max-width: 1149px) {
    width: 100%;
    height: 100vh;

    background-size: cover;
  }

  @media (max-width: 550px) {
    gap: 25px;
  }
`
export const Wrapper = styled.div`
  width: 83.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 4.5rem;
    letter-spacing: 0;
    text-align: center;
    color: #fdfdfd;

    &:first-of-type {
      margin-top: 43px;
    }
  }
`

export const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  gap: 20px;
  padding-bottom: 43px;
  border-bottom: 1.5px solid #fdfdfd;
  width: 100%;

  @media (max-width: 799px) {
    flex-direction: column;
  }

  @media (max-width: 400px) {
    padding: 0;
    padding-bottom: 40px;
  }

  h1 {
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 3.9rem;
    letter-spacing: 0.06em;
    color: #fdfdfd;
  }

  img {
    width: 264px;
    height: 81px;
  }
`
